import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './input.css';
import "./services/i18n";
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPBno1txqAAKUbMUfvvRTYYtH7Q9PSQtE",
  authDomain: "groupe-abed-website.firebaseapp.com",
  projectId: "groupe-abed-website",
  storageBucket: "groupe-abed-website.appspot.com",
  messagingSenderId: "948088305542",
  appId: "1:948088305542:web:6cf9fc59117186145d5e07",
  measurementId: "G-ML5PM4YXM1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
