import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import LanguageDetector from 'i18next-browser-languagedetector';
// "Inline" English and Arabic translations. 
// We can localize to any language and any number of languages.
const resources = {
  en: {
    translation: {
      info: "This website is under development",
      contact:"Contact this email for more information :"
    },
  },
  fr: {
    translation: {
        info: "Ce site web est en cours de développement",
        contact:"Contactez cet e-mail pour plus d'informations :"
    },
    ar: {
        translation: {
            info: "هذا الموقع هو قيد التطوير",
            contact:"اتصل بهذا البريد الإلكتروني لمزيد من المعلومات :"
        },
    },
  },
};

i18next
.use(LanguageDetector).use(initReactI18next).init({
    resources,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;