import { useTranslation } from "react-i18next";
function App() {
  const {t} = useTranslation()
  return (
    <div className="  w-screen h-screen bg-slate-600 ">

   <div className="p-10 space-y-10 flex flex-col items-center justify-center h-screen">
  <div className="text-4xl text-white">{t("info")}</div>
  <div className="text-2xl text-white">{t("contact")} <a style={{color:'blue'}} href="mailto:info@groupe-abed.com">info@groupe-abed.com</a></div>
   
   
   </div>
 


</div>
  );
}

export default App;
